import React, { Component } from "react";
import axios from "axios";
import ls from "local-storage";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { AlertModal } from "../../Components/UI/Modal/AlertModal";
import {
  CancelTokenSource,
  api_event_poll_url,
  api_logout_url,
} from "../../config";
import { route } from "../Routes";
import Header from "../Utilities/Header";
import "./scss/shell.scss";

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    if (ls.get("auth") !== null) {
      config.headers.common["Authorization"] = ls.get("auth").access_token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Detect 401 error & request.responseURL does not include "eventpoll"
    if (
      _.get(error, "response.status") === 401 &&
      !_.get(error, "response.request.responseURL").includes("eventpoll")
    ) {
      ls.remove("auth");
      window.location.href = route.login.link;
    }

    return Promise.reject(error);
  }
);

// // Add a response interceptor
// axios.interceptors.response.use(
//   function(resp) {

//     if (resp.data !== null) {
//       if (
//         resp.data.message !== undefined &&
//         resp.data.message === "Authenticated"
//       ) {
//         this.setState({
//           ...this.state,
//           popup: {
//             ...this.state.popup,
//             show: false
//           }
//         });
//       } else if (
//         resp.data.status !== undefined &&
//         resp.data.status === "Unauthorized"
//       ) {
//         this.setState({
//           ...this.state,
//           popup: {
//             show: true,
//             title: "Event Ended",
//             message: resp.data.message
//           }
//         });
//       } else if (
//         resp.data.message !== undefined &&
//         resp.data.message === "Unauthenticated"
//       ) {
//         this.setState({
//           ...this.state,
//           popup: {
//             show: true,
//             title: "Logged out",
//             message: "Your session has ended, please login again"
//           }
//         });
//       }
//     }

//     return resp;
//   },
//   function(error) {
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

const POLLING_DELAY = 5000; // 5s

class Shell extends Component {
  pollTimer = null;

  state = {
    title: "",
    updateNav: true,
    popup: {
      show: false,
      title: "",
      message: "",
    },
    unreadWardenMsg: false,
    unreadEvacueeMsg: false,
    alertModal: {
      isOpen: false,
      message: "",
      variant: "", // success | error | warn
    },
  };

  handleOpenModal = (message, variant) => {
    this.setState({
      alertModal: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseModal = () => {
    this.setState({
      alertModal: {
        isOpen: false,
        message: "",
      },
    });

    window.location.replace(route.reportinlist.link);
  };

  headerTitle = () => {
    let curUrl = window.location.pathname;

    let curUrlArr = curUrl.split("/");
    curUrlArr.shift();
    this.setState({
      ...this.state,
      title: this.searchTitle(route, curUrlArr),
      updateNav: !this.state.updateNav,
    });
  };

  searchTitle = (obj, search, i = 0) => {
    if (search[i] === undefined) {
      return obj.title;
    }
    for (var prop in obj) {
      if (search[i] === prop.toString()) {
        i = i + 1;
        return this.searchTitle(obj[prop], search, i);
      } else if ("title" === prop.toString()) {
        return obj.title;
      }
    }
  };

  /**
   * This method logs user out from the application
   * by deleting ther access token from the server
   * and also deleting local storage data
   */
  handleLogout = () => {
    axios
      .delete(api_logout_url, {
        cancelToken: CancelTokenSource.token,
      })
      .then((resp) => {
        ls.clear();
        this.props.history.push(route.login.link);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.mesage);
        }
      });
  };

  isNotAllowedPath = () => {
    const pathName = window.location.pathname;

    return (
      pathName.includes("/reportinlist") ||
      pathName.includes("/event/management") ||
      pathName.includes("/warden-assignment") ||
      pathName.includes("/withdraw")
    );
  };

  pollCheckEvent = () => {
    if (!this.isNotAllowedPath()) {
      // Cancel any previous ongoing request
      if (this.cancelPollTokenSource) {
        this.cancelPollTokenSource.cancel(
          "Operation canceled due to navigation"
        );
      }
      this.cancelPollTokenSource = axios.CancelToken.source();

      axios
        .get(api_event_poll_url, {
          cancelToken: this.cancelPollTokenSource.token,
        })
        .then((resp) => {
          if (resp.data.status !== "ok" && resp.data.message !== undefined) {
            this.setState({
              ...this.state,
              popup: {
                show: true,
                message: resp.data.message,
              },
            });
          }

          if (
            resp.data.status === "ok" &&
            resp.data.warden_unread_msg !== undefined &&
            resp.data.evacuee_unread_msg !== undefined
          ) {
            this.setState({
              ...this.state,
              unreadWardenMsg: resp.data.warden_unread_msg,
              unreadEvacueeMsg: resp.data.evacuee_unread_msg,
            });
          }

          // handle signout when event ended
          if (
            resp.data.status === "ok" &&
            !resp.data.event.status &&
            !this.isNotAllowedPath()
          ) {
            this.setState({
              ...this.state,
              alertModal: {
                isOpen: true,
                message:
                  "The event has been concluded. All wardens will be signed out.",
                variant: "warn",
              },
            });
          }
        });
    }
  };

  componentDidMount() {
    this.headerTitle();
    if (ls.get("auth") !== undefined && ls.get("auth") !== null) {
      this.pollTimer = setInterval(() => {
        if (!this.state.popup.show) {
          this.pollCheckEvent();
        }
      }, POLLING_DELAY);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.headerTitle();
      if (this.cancelPollTokenSource) {
        this.cancelPollTokenSource.cancel(
          "Component unmounted, request canceled"
        );
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollTimer);
    if (this.cancelPollTokenSource) {
      this.cancelPollTokenSource.cancel(
        "Component unmounted, request canceled"
      );
    }
  }

  renderPopup = () => {
    if (this.state.popup.show) {
      return (
        <div className="overlay_container">
          <div className="overlay_box-auto">
            <div className="overlay_message-auto blue-grey-text darken-3">
              {this.state.popup.message}
            </div>
            <div className="overlay_button_ok-auto">
              <button onClick={this.handleLogout}>Logout</button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderMessageNotification = () => {
    let message = "Unread Message from";
    if (this.state.unreadEvacueeMsg && this.state.unreadWardenMsg) {
      message = message + " Evacuees and Wardens";
    } else if (this.state.unreadEvacueeMsg) {
      message = message + " Evacuees";
    } else if (this.state.unreadWardenMsg) {
      message = message + " Wardens";
    }

    if (this.state.unreadEvacueeMsg || this.state.unreadWardenMsg) {
      return <div className="newMessageNotification">{message}</div>;
    } else {
      return null;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.renderPopup()}
        {this.renderMessageNotification()}

        {/* Remove Header for selected routes or pages */}
        {!this.props.hideHeader.includes(
          `/${this.props.location.pathname.split("/")[1]}`
        ) && (
          <Header
            showBackBtn={this.props.showBackBtn}
            showBurgerMenu={this.props.showBurgerMenu}
            title={this.state.title}
            menuDrag={this.props.menuDrag}
            showNav={this.props.showNav}
            updateNav={this.state.updateNav}
            open={(callback) => {
              this.props.showSideNav(true, callback);
            }}
            close={(callback) => {
              this.props.showSideNav(false, callback);
            }}
          />
        )}

        {this.props.children}

        <AlertModal
          isOpen={this.state.alertModal.isOpen}
          message={this.state.alertModal.message}
          variant={this.state.alertModal.variant}
          onClose={this.handleCloseModal}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Shell);
